import DetailLayout from "components/DetailLayout/DetailLayout";
import { FC } from "react";
import { MainLayout } from "../../layout/MainLayout";
import "./index.scss";
import BrandSection from "./sections/BrandSection";
import CarouselSection from "./sections/CarouselSection";
import ContactSection from "./sections/ContactSection";
import FeatureSection from "./sections/FeatureSection";
import ServiceSection from "./sections/ServiceSection";
import TeamSection from "./sections/TeamSection";

const HomePage: FC = () => {
  return (
    <MainLayout>
      <div className="main-body">
        <CarouselSection />
        <FeatureSection />
        <section className="details-area">
          <div className="container">
            <DetailLayout />
          </div>
        </section>
        <ServiceSection />
        <ContactSection />
        <BrandSection />
        <TeamSection />
      </div>
    </MainLayout>
  );
};

export { HomePage };