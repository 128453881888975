import { createPortal } from "react-dom";
import "./loading.style.scss";
import { useEffect } from "react";
import useScrollBlock from "utils/hooks/useScrollBlock";

const Loading = () => {
  const { blockScroll, allowScroll } = useScrollBlock();
  useEffect(() => {
    blockScroll();
    return () => {
      allowScroll();
    };
  });

  return createPortal(
    <div className="loading-box">
      <div className="spinner-container">
        <div className="spinner-border text-primary loading-spinner">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Loading;
