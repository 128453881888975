import React, { FC, useState } from "react";
import "./index.scss";
import { CaretUpFill } from "react-bootstrap-icons";

const ScrollToTopBtn: FC = () => {
    let scrollTopButton: HTMLElement | null;
    const [buttonDisplay, setButtonDisplay] = useState("scrollTopBtnHide");

    window.onscroll = function () {
        scrollTopButton = document.getElementById("btn-back-to-top");
        scrollFunction(scrollTopButton);
    };

    function scrollFunction(scrollTopButton: HTMLElement | null) {
        if (scrollTopButton) {
            if (
                document.body.scrollTop > 20 ||
                document.documentElement.scrollTop > 20
            ) {
                setButtonDisplay("scrollTopBtnDisplay");
            } else {
                setButtonDisplay("scrollTopBtnHide");
            }
        }
    }

    function backToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    return (
        <button
            id="btn-back-to-top"
            onClick={backToTop}
            className={`scrollTopBtnDisplay ${buttonDisplay}`}
        >
            <CaretUpFill color="white" size={15} />
        </button>
    );
};

export { ScrollToTopBtn };
