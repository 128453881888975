import Carousel from "react-bootstrap/Carousel";
import "../index.scss";

export default function CarouselSection() {
  return (
    <section className="carousel-area">
      <Carousel
        interval={2000}
        indicators={false}
        controls={false}
      >
        <Carousel.Item>
          <div className="carousel-image-container">
            <div className="d-block container mx-auto carousel-text-container text-center">
              <div className="row">
                <div className="col-12 safari-center">
                  <div className="banner-text-title fadeInUp-animation">
                    <h1 className="text-capitalize">WESGAN</h1>
                  </div>
                  <div className="banner-text-subtitle fadeInUp-animation">
                    <h5>
                      ระบบตรวจนับทรัพย์สิน <br className="d-inline d-sm-none" />ที่ตอบโจทย์การบริหารจัดการทรัพย์สินองค์กร
                      <br />
                      สแกนเร็ว ใช้งานง่าย ลดเวลาการทำงาน
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  );
}
