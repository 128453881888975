import React, { ChangeEvent } from "react";
import { Col, FloatingLabel, Form } from "react-bootstrap";

export type QuotationsInputType = {
  label: string;
  type: "text" | "password" | "email" | "number";
  name?: string;
  placeholder?: string;
  feedbackText?: string;
  required?: boolean;
  value?: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const QuotationsInput = ({
  name,
  type,
  label,
  placeholder,
  feedbackText,
  required,
  onChange,
}: QuotationsInputType) => {
  const inputCol = "12";
  const colClassName: string = "mb-2";

  return (
    <Col xs={inputCol} className={colClassName}>
      <FloatingLabel
        controlId="floatingInputGrid"
        label={required ? label + " *" : label}
      >
        <Form.Control
          name={name}
          type={type}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
        />
        <Form.Control.Feedback type="invalid">
          {feedbackText}
        </Form.Control.Feedback>
      </FloatingLabel>
    </Col>
  );
};
