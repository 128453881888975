import { FC, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import {
  Clock,
  Envelope,
  GeoAlt,
  Grid3x3GapFill,
  List,
  Telephone,
} from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import wesgan_logo from "../../assets/logo-orange.png";
import useWindowDimensions from "../../helpers/WindowDimensions";
import "./Header.scss";

const Header: FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [showSideNav, setShowSideNav] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const { width } = useWindowDimensions();
  const [navLogoClass, setNavLogoClass] = useState("d-xl-none");
  const [navGroupClass, setNavGroupClass] = useState("col-xl-8 col-xxl-9");
  const location = useLocation();
  const [url, setUrl] = useState("");

  const handleCloseNav = () => setShowSideNav(false);
  const handleShowNav = () => setShowSideNav(true);

  const handleCloseAddress = () => setShowAddress(false);
  const handleShowAddress = () => setShowAddress(true);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 200;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom && width > 992) {
      isVisible && setIsVisible(false);
      isVisible && setNavLogoClass("col-xl-1");
      isVisible && setNavGroupClass("col-xl-7 col-xxl-8");
    } else {
      setIsVisible(true);
      setNavLogoClass("d-xl-none");
      setNavGroupClass("col-xl-8 col-xxl-9");
    }
  };

  const pageAddClassActive = (currentPage: string, starterClass: string) => {
    return `${starterClass}${url === `/${currentPage}` ? "-active" : ""}`;
  };

  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      {isVisible && (
        <div className="logo-container d-none d-xl-block">
          <a href="/">
            <img src={wesgan_logo} alt="wesgan_logo" className="logo-size" />
          </a>
        </div>
      )}
      <div className="right-side flex-grow-1">
        {isVisible && (
          <div className="d-none d-md-flex justify-space-between p-2 top-right">
            <div className="d-flex col-xl-6 col-md-12 header-contact-container1">
              <div className="p-2 header-contact-info">
                <Telephone size={20} />
                <span className="d-inline-block padding-left-10">
                  <a href="tel:+66659095690" className="header-contact-link">
                    065-909-5690
                  </a>
                </span>
              </div>
              <div className="p-2 header-contact-info">
                <GeoAlt size={20} />
                <span className="d-inline-block padding-left-10">
                  <a
                    href="https://maps.app.goo.gl/EDwubvrvPSPQE9n48"
                    target="_blank"
                    rel="noreferrer"
                    className="header-contact-link"
                  >
                    Netconsult Office, Bangkok, Thailand
                  </a>
                </span>
              </div>
            </div>
            <div className="d-flex col-xl-6 col-md-12 header-contact-container2">
              <div className="p-2 header-contact-info">
                <Envelope size={20} />
                <span className="d-inline-block padding-left-10">
                  <a
                    href="mailto:support@netconsult.co.th"
                    className="header-contact-link"
                  >
                    support@netconsult.co.th
                  </a>
                </span>
              </div>
              <div className="p-2 header-contact-info">
                <Clock size={20} />
                <span className="d-inline-block padding-left-10">
                  <a href="#!" className="header-contact-unlink">
                    Mon-Fri: 08:30 - 17:00
                  </a>
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between">
          <div
            className={`d-flex col-xs-6 col-sm-6 col-md-1 col-lg-1 align-items-center ${navLogoClass} logo-padding`}
          >
            <a href="/">
              <img
                src={wesgan_logo}
                alt="wesgan_logo"
                className="logo-size-mobile"
              />
            </a>
          </div>
          <div
            className={`d-none d-md-flex col-md-10 col-lg-7 ${navGroupClass} nav-group`}
          >
            <div className={`d-flex p-3 nav-button`}>
              <a href="/" className={pageAddClassActive("", "nav-button-text")}>
                <h5>Home</h5>
              </a>
            </div>
            <div className={`d-flex p-3 nav-button`}>
              <a
                href="/features"
                className={pageAddClassActive("features", "nav-button-text")}
              >
                <h5>Features</h5>
              </a>
            </div>
            <div className={`d-flex p-3 nav-button`}>
              <a
                href="/contact-us"
                className={pageAddClassActive("contact-us", "nav-button-text")}
              >
                <h5>Contact Us</h5>
              </a>
            </div>
            <div className={`d-flex d-lg-none p-3 nav-button`}>
              <a
                href="/quotation"
                className={pageAddClassActive("quotation", "nav-button-text")}
              >
                <h5>Quotation</h5>
              </a>
            </div>
          </div>
          <div className="d-none d-md-flex col-md-1 col-lg-4 col-xl-4 col-xxl-3 justify-content-end">
            <div
              className="d-none d-md-flex nav-action align-items-center border-left-grey"
              onClick={handleShowAddress}
            >
              <Grid3x3GapFill size={20} />
            </div>
            <div className="d-none d-lg-flex p-3 border-left-grey align-items-center">
              <a href="/quotation" className="text-decoration-none">
                <div className="quotation-button wg-btn-primary">
                  <h5 className="quotation-text">Quotation</h5>
                </div>
              </a>
            </div>
          </div>
          <div className="d-flex col-6 col-sm-6 justify-content-end">
            <div
              className="d-flex nav-action p-4 align-items-center"
              onClick={handleShowNav}
            >
              <List size={25} className="nav-menu" />
            </div>
          </div>
        </div>
      </div>
      <Offcanvas
        show={showSideNav}
        onHide={handleCloseNav}
        placement="end"
        id="SideNav-Canvas"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <img src={wesgan_logo} alt="wesgan_logo" className="logo-size" />
          </Offcanvas.Title>
          <button
            type="button"
            className="btn-close address-button-close"
            aria-label="Close"
            onClick={handleCloseNav}
          ></button>
        </Offcanvas.Header>
        <Offcanvas.Body className="navmenu-offcanvas-body">
          <div className="navmenu-action">
            <div
              className={pageAddClassActive(
                "",
                "d-flex p-2 navmenu-pagelink-container"
              )}
            >
              <a
                href="/"
                className={pageAddClassActive("", "navmenu-pagelink-button")}
              >
                <h5>Home</h5>
              </a>
            </div>
            <div
              className={pageAddClassActive(
                "features",
                "d-flex p-2 navmenu-pagelink-container"
              )}
            >
              <a
                href="/features"
                className={pageAddClassActive(
                  "features",
                  "navmenu-pagelink-button"
                )}
              >
                <h5>Features</h5>
              </a>
            </div>
            <div
              className={`d-flex p-2 navmenu-pagelink-container${url === "/contact-us" ? "-active" : ""
                }`}
            >
              <a
                href="/contact-us"
                className={`navmenu-pagelink-button${url === "/contact-us" ? "-active" : ""
                  }`}
              >
                <h5>Contact Us</h5>
              </a>
            </div>
            <div
              className={pageAddClassActive(
                "quotation",
                "d-flex p-2 navmenu-pagelink-container"
              )}
            >
              <a
                href="/quotation"
                className={pageAddClassActive(
                  "quotation",
                  "navmenu-pagelink-button"
                )}
              >
                <h5>Quotation</h5>
              </a>
            </div>
            <div
              className={pageAddClassActive(
                "privacy-policy",
                "d-flex p-2 navmenu-pagelink-container"
              )}
            >
              <a
                href="/privacy-policy"
                className={pageAddClassActive(
                  "privacy-policy",
                  "navmenu-pagelink-button"
                )}
              >
                <h5>Privacy Policy</h5>
              </a>
            </div>
          </div>
          <div className="navmenu-info p-4">
            <h6 className="pb-3">
              <Envelope size={20} />
              <span className="d-inline-block padding-left-10">
                support@netconsult.co.th
              </span>
            </h6>
            <h6 className="pb-3">
              <Telephone size={20} />
              <span className="d-inline-block padding-left-10">
                065-909-5690
              </span>
            </h6>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showAddress}
        onHide={handleCloseAddress}
        placement="end"
        id="Address-Canvas"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className="d-flex align-items-center">
              <img src={wesgan_logo} alt="wesgan_logo" className="logo-size" />
            </div>
          </Offcanvas.Title>
          <button
            type="button"
            className="btn-close address-button-close"
            aria-label="Close"
            onClick={handleCloseAddress}
          ></button>
        </Offcanvas.Header>
        <Offcanvas.Body className="address-offcanvas-body">
          <hr className="address-hr" />
          <div className="address-container mb-3">
            <h5>Bangkok Office</h5>
            <p>
              1 Prompan 2 Building,
              <br />
              8th Floor, Unit 811,
              <br />
              Soi Ladprao 3, Ladprao Road,
              <br />
              Jompol, Chatuchak,
              <br />
              Bangkok, Thailand,
              <br />
              10900
            </p>
          </div>
          <div className="address-container">
            <h5>Chiang Mai Office</h5>
            <p>
              314 Village No.7,
              <br />
              Suthep,
              <br />
              Muang Chiang Mai,
              <br />
              Chiang Mai, Thailand
              <br />
              50200
            </p>
          </div>
          <hr className="address-hr" />
          <div className="phone-container mb-3">
            <h5>Phone Number</h5>
            <small>Sale</small>
            <p className="mb-1">062-605-4365</p>
            <small>Accountant</small>
            <p className="mb-1">065-909-5690</p>
          </div>
          <hr className="address-hr" />
          <div className="email-container">
            <h5>Email Address</h5>
            <small>Contact sale</small>
            <p className="mb-3">sale@digitalai.co.th</p>
            <small>Contact technician</small>
            <p className="mb-3">wesgan@netconsult.co.th</p>
            <small>Confirm payment</small>
            <p className="mb-1">gatesuda.r@digitalai.co.th</p>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </nav>
  );
};

export { Header };
