import "../index.scss";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import brand_img1 from "../../../assets/img/home/brand/brand_img01.png";
import brand_img2 from "../../../assets/img/home/brand/brand_img02.png";
import brand_img3 from "../../../assets/img/home/brand/brand_img03.png";
import brand_img4 from "../../../assets/img/home/brand/brand_img04.png";
import brand_img5 from "../../../assets/img/home/brand/brand_img05.png";
import brand_img6 from "../../../assets/img/home/brand/brand_img06.png";

export default function BrandSection() {
  return (
    <section className="brands-area">
      <div className="container">
        <Swiper
          className="brand-swiper"
          slidesPerView={1}
          spaceBetween={30}
          autoplay={true}
          loop={true}
          breakpoints={{
            410: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 80,
            },
          }}
          modules={[Pagination, Autoplay]}
        >
          <SwiperSlide className="swiper-slide">
            <div className="brand-container">
              <img src={brand_img1} alt="brand_01" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="brand-container">
              <img src={brand_img2} alt="brand_02" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="brand-container">
              <img src={brand_img3} alt="brand_03" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="brand-container">
              <img src={brand_img4} alt="brand_04" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="brand-container">
              <img src={brand_img5} alt="brand_05" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}
