import { Button, Col, Form, Row } from "react-bootstrap";
import { QuotationsInput, QuotationsInputType } from "./quotationsInput";
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useState,
  forwardRef,
} from "react";
import Stripe from "../../../../components/stripe/stripe";
import { IQuotationStateModel } from "../../quotations.model";

export type QuotationsFormType = {
  state: IQuotationStateModel;
  setState: Dispatch<SetStateAction<IQuotationStateModel>>;
  onSubmitForm: () => void;
};

const QuotationsForm = forwardRef<HTMLFormElement, QuotationsFormType>(
  ({ state, setState, onSubmitForm }: QuotationsFormType, ref) => {
    const inputList: QuotationsInputType[] = [
      {
        name: "company-name",
        label: "Company / Business name",
        type: "text",
        placeholder: "Company/Business name",
        value: state.companyName,
        onChange: (e) => handleChange("companyName", e),
        required: true,
        feedbackText: "Company / Business name is a required field",
      },
      {
        name: "contact-name",
        label: "Contact name",
        type: "text",
        placeholder: "Contact name",
        onChange: (e) => handleChange("contactName", e),
        required: false,
      },
      {
        name: "phone-number",
        label: "Phone number",
        type: "text",
        placeholder: "Phone number",
        onChange: (e) => handleChange("phoneNumber", e),
        required: true,
      },
      {
        name: "email",
        label: "Email",
        type: "email",
        placeholder: "Email address",
        onChange: (e) => handleChange("email", e),
        required: true,
        feedbackText: "Invalid email",
      },
      {
        name: "number-asset",
        label: "Number of assets",
        type: "number",
        placeholder: "Number of assets",
        onChange: (e) => handleChange("numAsset", e),
        required: true,
        feedbackText: "Number of assets is a required field",
      },
      {
        name: "duration",
        label: "Duration of use",
        type: "text",
        placeholder: "Duration of use",
        onChange: (e) => handleChange("duration", e),
        required: false,
      },
    ];

    const [validated, setValidated] = useState(false);

    const handleChange = (
      stateName: keyof IQuotationStateModel,
      event: ChangeEvent<HTMLInputElement>
    ) => {
      setState((prev) => ({ ...prev, [stateName]: event.target.value }));
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity()) {
        onSubmitForm();
        setValidated(false);
      }
    };

    return (
      <Row className="">
        <Col
          className="d-none d-sm-none d-lg-none d-xl-block p-0"
          md="10"
          lg="6"
        >
          <div className="imageCover">
            <div className="d-flex flex-grow-1 flex-column justify-content-center h-100 align-items-center px-4">
              {/* <img src={wgLogo} alt="" /> */}
            </div>
          </div>
        </Col>
        <Col md="12" xl="6" lg="12" className="p-5 p-sm-5  p-md-5 d-flex">
          <div className="w-100 justify-content-center">
            <h2 className="text-secondary">Quotation</h2>
            <Stripe />
            <div className="text-secondary d-xl-none mt-3">
              <h4 className="mb-1">ราคาพิเศษ</h4>
              <span>สำหรับการขอผ่านหน้าเว็บเท่านั้น</span>
            </div>

            <Form
              ref={ref}
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className=""
            >
              <Row className="mt-4">
                {inputList.map((formInput, idx) => (
                  <QuotationsInput key={idx} {...formInput} />
                ))}
              </Row>
              <Row className="mt-1">
                <Col xs="12" sm="12" md="7" lg="8" xl="6">
                  <div className="d-grid">
                    <Button variant="primary" type="submit" size="lg">
                      Send
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }
);

export default QuotationsForm;
