import { Telephone } from "react-bootstrap-icons";
import "../index.scss";
import { useInView } from "react-intersection-observer";

export default function ContactSection() {
  const { ref, inView } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  return (
    <section className="contact-area">
      <div className="contact-bg-wrap">
        <div className="contact-image"></div>
      </div>
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-xl-5 col-lg-6">
            <div className="contact-content">
              <h2
                className={`contact-title animate ${
                  inView && "fadeInUp-animation"
                }`}
                ref={ref}
              >
                Offering the best<span> Experience Of </span>Wesgan
              </h2>
              <div className="contact-phone">
                <div className="contact-phone-icon">
                  <Telephone size={40} />
                </div>
                <div className="contact-number">
                  <span>Toll Free Call</span>
                  <a href="tel:+66626054365">062-605-4365</a>
                </div>
              </div>
              <a
                href="/support"
                className="trial-link wg-btn-primary text-capitalize"
              >
                free 14 days trial
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
