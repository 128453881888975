import React from "react";
import "./SocialWidget.scss";
import { Line, Messenger } from "react-bootstrap-icons";

export default function SocialWidget() {
  return (
    <div className="social-widget-container">
      {/* <a
        href="https://m.me/61550300530857"
        target="_blank"
        className="text-white pb-3"
        rel="noreferrer"
      >
        <div className="btn-container btn-messenger btn-pop">
          <Messenger size={25} />
        </div>
      </a> */}
      <a
        href="https://lin.ee/GuI68dF"
        target="_blank"
        className="text-white"
        rel="noreferrer"
      >
        <div className="btn-container btn-line btn-pop">
          <Line size={25} />
        </div>
      </a>
    </div>
  );
}
