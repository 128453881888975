export type NavListType = {
  title: string;
  subTitle: string;
  navTo: string;
};

const sideNavItems: NavListType[] = [
  {
    title: "Cloud Services",
    subTitle: "",
    navTo: "#cloud_services",
  },
  {
    title: "3 Simple Steps",
    subTitle: "",
    navTo: "#three_simple_step",
  },
  {
    title: "Our Features",
    subTitle: "",
    navTo: "#our_features",
  },
];

export default sideNavItems;
