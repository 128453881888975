import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";

type fireToast = (
  message: string,
  alertType: SweetAlertIcon,
  options?: SweetAlertOptions
) => void;

type fireAlert = (
  title: string,
  message: string,
  alertType: SweetAlertIcon,
  options?: SweetAlertOptions
) => void;

const useAlert = () => {
  const trickAlert = (option: SweetAlertOptions) => {
    Swal.fire(option);
  };

  const fireToast: fireToast = (message, alertType, options) => {
    const toastConfig: SweetAlertOptions = {
      toast: true,
      title: message,
      icon: alertType,
      position: "top-end",
      showConfirmButton: false,
      timer: 1600,
      ...options,
    };
    return trickAlert(toastConfig);
  };

  const fireAlert: fireAlert = (title, message, alertType, options) => {
    const alertConfig: SweetAlertOptions = {
      icon: alertType,
      title: title,
      text: message,
    };

    return trickAlert(alertConfig);
  };

  const fireError = (err: any) => {
    let messages: string[] = [];

    if (typeof err === "string") {
      messages.push(err);
    } else {
      if (err.message || err.errors) {
        for (let key in err) {
          messages.push(...err.errors[key]);
        }
      } else {
        // errors from API
        for (let key in err) {
          messages.push(...err[key]);
        }
      }
    }

    return fireAlert("Oops...", messages.join(", "), "error");
  };

  return { fireToast, fireAlert, fireError };
};

export default useAlert;
