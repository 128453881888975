import "./contractWithUs.style.scss";

const ContractWithUs = () => {
  return (
    <div className="contract_widget">
      <div className="contract_widget__content">
        <h2 className="contract_widget__title text-secondary">
          If You Need Any Help Contact With Us
        </h2>
        <a href="tel:+66659095690">
          <span className="fw-bold">
            <i className="bi bi-telephone-outbound "></i>
          </span>
          065-909-5690
        </a>
      </div>
    </div>
  );
};

export default ContractWithUs;
