import "./floatingBox.style.scss";

export type floatingBoxType = {
  children: string | JSX.Element | JSX.Element[];
};

const FloatingBox = ({ children }: floatingBoxType) => {
  return <div className="floating-box bg-white">{children}</div>;
};

export default FloatingBox;
