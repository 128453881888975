export type OurFeaturesType = {
  title: string;
};

const ourFeatures: OurFeaturesType[] = [
  {
    title: "ตรวจนับผ่านโทรศัพท์มือถือ Tracking and Scan QR ",
  },
  {
    title: "Scan ได้อย่างรวดเร็ว ด้วยเทคโนโลยีที่เราพัฒนา Fast Tracking",
  },
  {
    title: "ถ่ายรูปและแนบรูปภาพรายการทรัพย์สิน Take and Upload Images",
  },
  {
    title: "เปลี่ยน ย้าย สถานะ ทรัพย์สิน Changing Asset Status",
  },
  {
    title: "นำเข้าทรัพย์สินที่ไม่ได้อยู่ในฐานข้อมูล",
  },
  {
    title: "มีระบบหลังบ้านสำหรับจัดการข้อมูลตั้งต้นระบบ - Data Config",
  },
  {
    title: "รายงานรูปแบบกราฟ - BI Dashboard",
  },
  {
    title: "รายงานรูปแบบ ไฟล์ พร้อมตัวกรองการค้นหา - Export  Data",
  },
  {
    title: "อนุมัติงานตรวจนับทรัพย์สิน - Approve Process",
  },
  {
    title: "อนุมัติทรัพย์สินที่อยู่นอกระบบ",
  },
  {
    title:
      "จัดการรูปภาพทรัพย์สิน นำเข้าและเรียกดูข้อมูลรูปภาพเป็นชุด Image Processing",
  },
  {
    title: "เรียกดูรายงานได้ทันทีที่ตรวจนับสำเร็จ Realtime Report",
  },
];
export default ourFeatures;
