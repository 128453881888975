import { useInView } from "react-intersection-observer";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import member5 from "../../../assets/img/home/member/photo.png";
import member4 from "../../../assets/img/home/member/knot.png";
import member3 from "../../../assets/img/home/member/tong.png";
import member2 from "../../../assets/img/home/member/oat.png";
import member1 from "../../../assets/img/home/member/x.png";
import "../index.scss";

export default function TeamSection() {
  const { ref, inView } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  return (
    <section className="team-area">
      <div className="container">
        <div className="team-intro">
          <h6 className="team-intro1">MEET OUR TEAM</h6>
          <h1
            className={`team-intro2 animate ${inView && "fadeInUp-animation"}`}
            ref={ref}
          >
            Meet Our Specialized Team Of Developers
          </h1>
        </div>

        <Swiper
          className="team-swiper"
          slidesPerView="auto"
          spaceBetween={30}
          autoplay={true}
          loop={true}
          breakpoints={{
            410: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1400: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          modules={[Pagination, Autoplay]}
        >
          <SwiperSlide className="swiper-slide">
            <div className="team-container">
              <img src={member1} alt="member_01" className="team-member-img" />
            </div>
            <div className="team-position-container">
              <h4>Watcharapong K.</h4>
              <h6>Software Development Manager</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="team-container">
              <img src={member2} alt="member_02" className="team-member-img2" />
            </div>
            <div className="team-position-container">
              <h4>Taweepol K.</h4>
              <h6>Senior Software Developer</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="team-container">
              <img src={member3} alt="member_03" className="team-member-img" />
            </div>
            <div className="team-position-container">
              <h4>Nattapol T.</h4>
              <h6>Senior Software Developer</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="team-container">
              <img src={member4} alt="member_04" className="team-member-img" />
            </div>
            <div className="team-position-container">
              <h4>Korawit S.</h4>
              <h6>Software Developer</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="team-container">
              <img src={member5} alt="member_05" className="team-member-img" />
            </div>
            <div className="team-position-container">
              <h4>Pongpanoth P.</h4>
              <h6>Software Developer</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="team-container">
              <img src={member1} alt="member_01" className="team-member-img" />
            </div>
            <div className="team-position-container">
              <h4>Watcharapong K.</h4>
              <h6>Software Development Manager</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="team-container">
              <img src={member2} alt="member_02" className="team-member-img2" />
            </div>
            <div className="team-position-container">
              <h4>Taweepol K.</h4>
              <h6>Senior Software Developer</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="team-container">
              <img src={member3} alt="member_03" className="team-member-img" />
            </div>
            <div className="team-position-container">
              <h4>Nattapol T.</h4>
              <h6>Senior Software Developer</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="team-container">
              <img src={member4} alt="member_04" className="team-member-img" />
            </div>
            <div className="team-position-container">
              <h4>Korawit S.</h4>
              <h6>Software Developer</h6>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="team-container">
              <img src={member5} alt="member_05" className="team-member-img" />
            </div>
            <div className="team-position-container">
              <h4>Pongpanoth P.</h4>
              <h6>Software Developer</h6>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}
