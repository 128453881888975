import Stripe from "components/stripe/stripe";
import "./brochure.style.scss";

const Brochure = () => {
  const brochureUrl = `${process.env.PUBLIC_URL}/download/wesgan_brochure_190667.pdf`;

  return (
    <div className="brochure-box">
      <h4 className="text-secondary">Brochure</h4>
      <Stripe />
      <p className="wg-text-body mt-2">
        ยังไม่ต้องตัดสินใจตอนนี้
        คุณสามารถดาวน์โหลดข้อมูลของเราเพื่อนำไปเปรียบเทียบได้ที่
      </p>
      <div className="mt-4">
        <a
          href={brochureUrl}
          className="brochure-btn"
          download
          target="_blank"
          rel="noreferrer"
        >
          <span>
            <i className="bi bi-file-earmark-pdf fw-bolder"></i>
          </span>
          PDF Download
        </a>
      </div>
    </div>
  );
};

export default Brochure;
