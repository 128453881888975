import { MouseEvent, useEffect } from "react";
import "./navMenu.style.scss";
import { useLocation } from "react-router-dom";
import sideNavItems from "pages/features/sideNav";

const NavMemu = () => {
  const { hash } = useLocation();

  const handleScollToHeader = (
    targetID: string,
    event?: MouseEvent<HTMLAnchorElement> | undefined
  ) => {
    window.location.hash = targetID;
    event?.preventDefault();
    const barHeight = 80;
    const margin = 15;
    const targetHeader = document.getElementById(targetID.replace("#", ""));
    if (targetHeader) {
      const sT = targetHeader.getBoundingClientRect().top - barHeight - margin;
      window.scrollTo(0, sT);
    }
  };

  useEffect(() => {
    if (hash) {
      handleScollToHeader(hash);
    }
  }, [hash]);

  return (
    <ul className="list-menu">
      {sideNavItems.map((item, idx) => (
        <li
          key={"navItem_" + idx}
          className={sideNavItems.length !== idx + 1 ? "item-mb" : ""}
        >
          <a
            href={item.navTo}
            onClick={(evt) => handleScollToHeader(item.navTo, evt)}
          >
            <span className="title">{item.title}</span>
            <i className="bi bi-arrow-right fs-4"></i>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default NavMemu;
