import { FC } from "react";
import { MainLayout } from "../../layout/MainLayout";
import "./index.scss";
import { Col, Container, Row } from "react-bootstrap";
import SideMenuBox from "./components/SideMenuBox/SideMenuBox";
import Brochure from "./components/Brochure/Brochure";
import NavMemu from "./components/NavMenu/NavMemu";
import DetailLayout from "../../components/DetailLayout/DetailLayout";
import FeatureCard, {
  IFeatureCardType,
} from "./components/FeatureCard/FeatureCard";
import ContractWithUs from "./components/ContactWithUs/ContractWithUs";
import bestPriceImg from "../../assets/img/features/best_price_3.png";
import featureParagraph from "./paragraphs";
import ourFeatures from "./ourFeatures";
import OurFeatureItem from "./components/OurFeatureItem/OurFeatureItem";
import Datasheet from "./components/DataSheet/DataSheet";

const FeaturesPage: FC = () => {
  const threeSimpleStep: IFeatureCardType[] = [
    {
      title: "สร้างใบงาน",
      subTitle:
        'สร้างใบงานกำหนดวัน <span className="d-none d-md-none d-lg-flex"><br></span>ตรวจนับ',
      icon: <i className="bi bi-file-earmark-text"></i>,
    },
    {
      title: "ตรวจนับ",
      subTitle: "สแกนผ่านมือถือได้ง่ายๆ แค่สแกน และบันทึก",
      icon: <i className="bi bi-qr-code-scan"></i>,
    },
    {
      title: "อนุมัติ",
      subTitle: "อนุมัติรายการผ่านหน้าจอระบบ ใช้งานง่าย ไม่ซับซ้อน",
      icon: <i className="bi bi-ui-checks"></i>,
    },
  ];

  return (
    <MainLayout>
      {/* <div className="bg-light header-box">
        <Container>
          <Row>
            <Col>
              <h2>Features</h2>
              <Stripe />
              <p className="">คุณสมบัติของระบบ</p>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className="detail-area">
        <Container>
          <Row>
            <Col
              xs={{ order: "last" }}
              sm={{ order: "last" }}
              lg={{ span: 3, order: "first" }}
              className="mt-3 mt-sm-3 mt-md-0"
            >
              <Row className="mb-4">
                <Col>
                  <SideMenuBox>
                    <NavMemu />
                  </SideMenuBox>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <ContractWithUs />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <SideMenuBox>
                    <Brochure />
                  </SideMenuBox>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SideMenuBox>
                    <Datasheet />
                  </SideMenuBox>
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ order: "first" }}
              sm={{ order: "first" }}
              className=" px-5"
              lg={{ span: 9, order: "last" }}
            >
              {/* Detail block */}
              <DetailLayout />

              <Row className="mb-4 pt-4">
                <Col md="12" lg="6" className="">
                  <h2
                    id="cloud_services"
                    className={`text-secondary feature_title`}
                  >
                    Cloud Services
                  </h2>
                  <p className="wg-text-body">
                    {featureParagraph.cloudServices}
                  </p>
                </Col>

                <Col md="12" lg="6" className="mb-4 mb-md-3">
                  <img src={bestPriceImg} className="img-frame" alt="" />
                </Col>
              </Row>

              <Row className="mb-4">
                <Col xs="12">
                  <h2
                    id="three_simple_step"
                    className="text-secondary feature_title"
                  >
                    3 Simple Steps to Process
                  </h2>
                  <p className="wg-text-body">
                    {featureParagraph.threeSimpleStep}
                  </p>
                </Col>
                <Col className="mt-4">
                  <Row>
                    {threeSimpleStep.map((stepItem, idx) => (
                      <FeatureCard
                        key={"threeSimpleStep" + idx}
                        icon={stepItem.icon}
                        title={stepItem.title}
                        subTitle={stepItem.subTitle}
                      />
                    ))}
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xs="12" className="mb-4">
                  <h2
                    id="our_features"
                    className="text-secondary feature_title"
                  >
                    Our Features
                  </h2>
                </Col>
                <Col>
                  {ourFeatures.map((ourFeatureItem, idx) => (
                    <Row key={idx}>
                      <OurFeatureItem title={ourFeatureItem.title} />
                    </Row>
                  ))}
                </Col>
              </Row>

              <Row className="mb-4"></Row>
            </Col>
          </Row>
        </Container>
      </div>
    </MainLayout>
  );
};

export { FeaturesPage };
