import React from "react";
import { Col } from "react-bootstrap";

import "./ourFeatureItem.style.scss";

type OurFeatureItem = {
  title: string;
};

const OurFeatureItem = ({ title }: OurFeatureItem) => {
  return (
    <div className="our-feature__item mb-3">
      <i className="bi bi-arrow-right-circle-fill fs-3 text-primary"></i>

      {title}
    </div>
  );
};

export default OurFeatureItem;
