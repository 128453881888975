import "./sideMenuBox.style.scss";

export type SideMenuBoxType = {
  children: string | JSX.Element | JSX.Element[];
};

const SideMenuBox = ({ children }: SideMenuBoxType) => {
  return <div className="box">{children}</div>;
};

export default SideMenuBox;
