import { Calculator, Easel, People, PieChartFill } from "react-bootstrap-icons";
import "../index.scss";
import service_deco1 from "../../../assets/img/home/service_deco01.png";
import service_deco2 from "../../../assets/img/home/service_deco02.png";
import { useInView } from "react-intersection-observer";

export default function ServiceSection() {
  const { ref, inView } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  return (
    <section className="services-area">
      <div className="service-decoration-img2">
        <img src={service_deco2} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="service-headtext">
              <span className="service-subtitle text-uppercase">
                Why We Are The Best
              </span>
              <h2
                className={`service-title text-capitalize animate ${
                  inView && "fadeInUp-animation"
                }`}
                ref={ref}
              >
                Let's Discover Our Service Features Charter
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-3 col-md-6">
            <div className="service-item">
              <div className="service-icon-container">
                <People size={60} className="icon-animated" />
              </div>
              <div className="service-content">
                <div className="service-content-title">
                  <h4 className="text-capitalize">Solutions and Consulting</h4>
                </div>
                <p>ให้คำปรึกษาการจัดการข้อมูลทรัพย์สินองค์กร</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="service-item">
              <div className="service-icon-container">
                <PieChartFill size={60} className="icon-animated" />
              </div>
              <div className="service-content">
                <div className="service-content-title">
                  <h4 className="text-capitalize">Data Analytics</h4>
                </div>
                <p>วิเคราะห์และออกแบบการใช้ข้อมูลให้มีประสิทธิภาพสูงสุด</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="service-item">
              <div className="service-icon-container">
                <Calculator size={60} className="icon-animated" />
              </div>
              <div className="service-content">
                <div className="service-content-title">
                  <h4 className="text-capitalize">Fixed Asset Counting</h4>
                </div>
                <p>บริการตรวจนับทรัพย์สินองค์กร</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="service-item">
              <div className="service-icon-container">
                <Easel size={60} className="icon-animated" />
              </div>
              <div className="service-content">
                <div className="service-content-title">
                  <h4 className="text-capitalize">In-house Training</h4>
                </div>
                <p>อบรมการใช้งาน ออกแบบหลักสูตรเฉพาะธุรกิจ</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-decoration-img">
        <img src={service_deco1} alt="" />
      </div>
    </section>
  );
}
