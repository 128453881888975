import { Col, Row } from "react-bootstrap";
import "./detailGroup.scss";

type DetailGroupType = {
  title: string;
  icon?: JSX.Element;
  children: string | JSX.Element | JSX.Element[];
};

const DetailGroup = ({
  title,
  icon = <i className="bi bi-question-circle"></i>,
  children,
}: DetailGroupType) => {
  return (
    <Row className="mt-3 detail-group">
      <Col className="d-none d-sm-none d-md-none d-lg-block p-0" lg={2}>
        <div className="detail-group__icon-box">
          <div className="detail-group__icon">{icon}</div>
        </div>
      </Col>
      <Col>
        <div className="detail-group__text-box p-3">
          <div>
            {" "}
            <h5>{title}</h5>{" "}
          </div>
          <div>{children}</div>
        </div>
      </Col>
    </Row>
  );
};

export default DetailGroup;
