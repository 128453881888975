import "../index.scss";
import {
  BarChartLine,
  Bullseye,
  Easel,
  Lightbulb,
} from "react-bootstrap-icons";

export default function FeatureSection() {
  return (
    <section className="features-area">
      <div className="container-fluid p-0">
        <div className="features_container_wrap">
          <div className="row g-0">
            <div className="col-xl-3 col-md-6 p-0">
              <div className="feature-item">
                <div className="feature-icon">
                  <BarChartLine size={40} className="icon-animated" />
                </div>
                <div className="feature-content">
                  <h4 className="feature-title">Tracking and Scan</h4>
                  <p className="feature-description">
                    การตรวจสอบทรัพย์สินโดยสแกนผ่าน Mobile
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 p-0">
              <div className="feature-item">
                <div className="feature-icon">
                  <Lightbulb size={40} className="icon-animated" />
                </div>
                <div className="feature-content">
                  <h4 className="feature-title">Secure</h4>
                  <p className="feature-description">
                    มั่นใจเรื่องความปลอดภัย ด้วย Cloud Services มาตรฐาน ISO/IEC
                    27001
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 p-0">
              <div className="feature-item">
                <div className="feature-icon">
                  <Bullseye size={40} className="icon-animated" />
                </div>
                <div className="feature-content">
                  <h4 className="feature-title">Real-time Report</h4>
                  <p className="feature-description">
                    รับ-ส่งข้อมูลแบบ Real-time สามารถดูข้อมูลได้อย่างทันท่วงที
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 p-0">
              <div className="feature-item">
                <div className="feature-icon">
                  <Easel size={40} className="icon-animated" />
                </div>
                <div className="feature-content">
                  <h4 className="feature-title">Quick Support</h4>
                  <p className="feature-description">
                    ไร้ข้อกังวลหลังใช้งาน เจ้าหน้าที่จะตอบกลับปัญหาภายใน 30 นาที
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
