import { FC } from "react";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import { ScrollToTopBtn } from "../components/ScrollToTopButton/ScrollToTopBtn";
import "./MainLayout.scss";
import SocialWidget from "components/SocialWidget/SocialWidget";

type Props = {
  children: React.ReactNode;
};

const MainLayout: FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <div className="fixed-navbar-height">{children}</div>
      <Footer />
      <ScrollToTopBtn />
      <SocialWidget />
    </>
  );
};
export { MainLayout };
