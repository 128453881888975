import {
  IQuotationRequestModel,
  IQuotationStateModel,
} from "pages/quotation/quotations.model";
import api from "./apiHelper";

const routeAPI: string = "sale";
class QuotationsAPI {
  public sendQuotation(
    request: IQuotationRequestModel
  ): Promise<BaseResponseAPI> {
    return api.post(`${routeAPI}/request-quotation`, request);
  }

  public responseTest(request: IQuotationStateModel) {
    const response = new Promise<{
      status: string;
      statusCode: number;
      data: IQuotationStateModel;
    }>((reslove, reject) => {
      const msg = { status: "success", statusCode: 200, data: request };
      setTimeout(() => reslove(msg), 1000);
    });

    return response;
  }
}

const qutationsAPI = new QuotationsAPI();
export default qutationsAPI;

export interface BaseResponseAPI<T = any> {
  success: boolean;
  data: T;
  errors: any;
  count: number;
  status: number;
}
