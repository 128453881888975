import { Col, Row } from "react-bootstrap";
import "./detailLayout.style.scss";
import presentImg from "../../assets/img/features/feature_img.png";
import { useInView } from "react-intersection-observer";

export type BulletListType = {
  title: string;
  subTitle: string;
};

const DetailLayout = () => {
  const paragraph1 =
    "ระบบบริหารจัดการทรัพย์สิน Wesgan ออกแบบมาเพื่อตอบโจทย์การทำงานที่ช่วยลดขั้นตอนการจัดการทรัพย์สินองค์กร การตรวจนับประจำปี การตรวจสอบและรายงานสรุปผล ให้สามารถดำเนินการตรวจนับ 100 ชิ้นต่างสถานที่ได้ภายใน 30 นาที";

  const bulletList: BulletListType[] = [
    {
      title: "Fast Response",
      subTitle: "15 Minutes",
    },
    {
      title: "Ready to Start",
      subTitle: "With in  24 Hours",
    },
  ];

  const { ref, inView } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  return (
    <>
      <Row className="mb-4">
        <Col md="12" lg="6" className="mb-4 mb-md-3">
          <img src={presentImg} className="img-frame" alt="" />
        </Col>
        <Col md="12" lg="6">
          <h2
            className={`text-secondary title animate ${
              inView && "fadeInUp-animation"
            }`}
            ref={ref}
          >
            สิ่งที่คุณจะได้รับจาก Wesgan
          </h2>
          <p className="wg-text-body">{paragraph1}</p>
          <ul className="bullet-list">
            {bulletList.map((item, idx) => (
              <li key={"bulletItem" + idx}>
                <span>
                  <i className="bi bi-arrow-right-circle-fill fs-4"></i>
                  {item.title}
                </span>

                {item.subTitle}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <p className="wg-text-body">
            มากกว่าการทำงานของระบบ การให้บริการหลังการขายถือเป็นเรื่องสำคัญที่
            Wesgan ให้ความสำคัญเป็นอันดับแรก เราการันตีทุกปัญหาของลูกค้า ทีมงาน
            support จะตอบสนองภายใน 15 นาที
            ตลอดระยะเวลาทำการโดยหลากหลายช่องทางที่ให้บริการ
          </p>
          <a href="/support" className="btn-pill wg-btn-primary">
            Click to Support
          </a>
        </Col>
      </Row>
    </>
  );
};

export default DetailLayout;
