import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/home";
import { ContactPage } from "./pages/contact-us";
import { FeaturesPage } from "./pages/features";
import { DemoPage } from "./pages/demo";
import { QuotationPage } from "./pages/quotation";
import { SupportPage } from "./pages/support";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { PrivacyPolicyPage } from "pages/privacy-policy";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route
          path="/quotation"
          element={
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
              <QuotationPage />
            </GoogleReCaptchaProvider>
          }
        />

        {/* <Route path="/support" element={<SupportPage />} /> */}
        {/* <Route path="/demo" element={<DemoPage />} /> */}
        <Route path="/features" element={<FeaturesPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
