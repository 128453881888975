import Stripe from "components/stripe/stripe";
import "./datasheet.style.scss";

const DataSheet = () => {
  const datasheetUrl = `${process.env.PUBLIC_URL}/download/wesgan_datasheet.pdf`;

  return (
    <div className="datasheet-box">
      <h4 className="text-secondary">Datasheet</h4>
      <Stripe />
      <p className="wg-text-body mt-2">
        ศึกษาข้อมูลผลิตภัณฑ์เพิ่มเติมและดาวน์โหลดได้ที่
      </p>
      <div className="mt-4">
        <a
          href={datasheetUrl}
          className="datasheet-btn"
          download
          target="_blank"
          rel="noreferrer"
        >
          <span>
            <i className="bi bi-file-earmark-pdf fw-bolder"></i>
          </span>
          PDF Download
        </a>
      </div>
    </div>
  );
};

export default DataSheet;
