import { Col } from "react-bootstrap";
import "./featureCard.style.scss";

export interface IFeatureCardType {
  icon: JSX.Element;
  title: string;
  subTitle?: string;
}

const FeatureCard = ({ icon, title, subTitle }: IFeatureCardType) => {
  return (
    <Col sm="6" lg="4" className="mb-4">
      <div className="feature-card">
        <div className="icon">{icon}</div>
        <div className="feature-card__content">
          <h4
            className="feature-card__title text-secondary"
            role="div"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h4>
          {!!subTitle && (
            <p
              className="wg-text-body"
              dangerouslySetInnerHTML={{ __html: subTitle }}
            ></p>
          )}
        </div>
      </div>
    </Col>
  );
};

export default FeatureCard;
