import axios from "axios";

import { stringify } from "querystring";

const baseURL = process.env.REACT_APP_MAIL_API_URL;

export default class Class {
  static async get(path: string, params?: any) {
    let qs: string = this.transformQueryString(params);
    return await axios
      .get(`${baseURL}/api/${path}${qs}`)
      .then((res) => res && res.data)
      .catch((err) => this.handleException(err));
  }

  static async post(path: string, data: any = null) {
    return await axios
      .post(`${baseURL}/api/${path}`, data)
      .then((res) => res && res.data)
      .catch((err) => this.handleException(err));
  }

  static async put(path: string, data: any = null) {
    return await axios
      .put(`${baseURL}/api/${path}`, data)
      .then((res) => res && res.data)
      .catch((err) => this.handleException(err));
  }

  static async patch(path: string, data: any = null) {
    return await axios
      .patch(`${baseURL}/api/${path}`, data)
      .then((res) => res && res.data)
      .catch((err) => this.handleException(err));
  }

  static async delete(path: string, params: any = null) {
    let qs: string = this.transformQueryString(params);
    return await axios
      .delete(`${baseURL}/api/${path}${qs}`)
      .then((res) => res && res.data)
      .catch((err) => this.handleException(err));
  }

  static async handleException(err: any) {
    console.log(`===== Handler API exception ===== `);
    console.log(err);
    console.log(`====================`);
    const statusCode = err.response?.status;
    if (statusCode === 401) {
      //   window.location.href = `/signout`;
      return {
        success: false,
        errors: [],
      };
    } else if (statusCode === 400) {
      if (err.response?.data instanceof Blob) {
        const blob: Blob = err.response?.data;
        const data = await blob.text();
        return JSON.parse(data);
      } else {
        return err.response?.data;
      }
    } else {
      throw err;
    }
  }

  static transformQueryString(params: any = null): string {
    let qs: string = "";
    if (params != null) {
      qs = "?" + stringify(params);
    }
    return qs;
  }
}
