import { FC, useCallback, useEffect, useRef, useState } from "react";
import { MainLayout } from "../../layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./quotations.style.scss";
import FloatingBox from "./components/floatingBox/floatingBox";
import QuotationsForm from "./components/form/quotationsForm";
import { IQuotationStateModel } from "./quotations.model";
import Loading from "./components/loading/loading";
import quotationAPI from "services/quotationsAPI";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import useAlert from "utils/hooks/useAlert";
import { ErrorCode } from "enum/errorCode";

export const quotationInitState: IQuotationStateModel = {
  companyName: "",
  contactName: "",
  email: "",
  phoneNumber: "",
  numAsset: 0,
  duration: "",
};

const QuotationPage: FC = () => {
  const [quotationData, setQuotationData] =
    useState<IQuotationStateModel>(quotationInitState);

  const [isLoading, setLoading] = useState(false);
  const counterRef = useRef<number>(0);
  const [token, setToken] = useState<any>("");

  const formRef = useRef<HTMLFormElement>(null);
  const { fireError, fireAlert } = useAlert();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      // console.log("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("request_quotation");
    setToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const trySendFormAgain = (error: any) => {
    for (let key in error) {
      if (key === ErrorCode.InvalidChaptcha) {
        if (counterRef.current < 3) {
          handleSendForm();
        } else {
          fireAlert(
            "Sorry",
            "Something went wrong. Please try again, or refresh the page.",
            "error"
          );
        }
        return;
      } else {
        fireError(error);
        return;
      }
    }
  };

  const handleSendForm = () => {
    const form = formRef.current;
    counterRef.current++;
    if (!isLoading) {
      setLoading(true);
      handleReCaptchaVerify().then(() => {
        quotationAPI
          .sendQuotation({ ...quotationData, reCaptcha: token })
          .then(({ success, errors }) => {
            if (success) {
              counterRef.current = 0;
              fireAlert(
                "Sent successfully",
                "Data has been received.",
                "success"
              );
              setLoading(false);
              form?.reset();
            } else {
              setLoading(false);
              trySendFormAgain(errors);
            }
          })
          .catch((err) => {
            console.log(err);
            fireAlert(
              "Something went wrong",
              "Please contact support, or try again later",
              "error"
            );
            setLoading(false);
          });
      });
    }
  };

  return (
    <MainLayout>
      <div className=" bg-light d-flex quotation-container">
        <Container className="">
          <Row className="justify-content-center">
            <Col md="8" xl="10" className="py-3 py-md-5">
              <FloatingBox>
                <QuotationsForm
                  ref={formRef}
                  state={quotationData}
                  setState={setQuotationData}
                  onSubmitForm={handleSendForm}
                />
              </FloatingBox>
            </Col>
          </Row>
        </Container>
      </div>
      {isLoading && <Loading />}
    </MainLayout>
  );
};

export { QuotationPage };
